
.embedded {
    --ion-background-color: transparent;
}

.embedded ion-modal::part(content) {
    max-height: 100%;
}

.field-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.ios .field-wrapper, .android .field-wrapper {
    flex-direction: column;
}

.web .ion-overlay-wrapper,
.web .ion-page {
    background: white !important;
}